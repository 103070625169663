<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <app-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to Interloan!
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Username" label-for="login-username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
                <b-button type="submit" variant="primary" block> Sign in </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import AppLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store';
// import { getHomeRouteForLoggedInUser } from '@/auth/utils';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import browserSignature from 'browser-signature';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const signature = browserSignature();

          useJwt
            .login({
              username: this.userEmail,
              password: this.password,
              deviceName: signature,
            })
            .then((response) => {
              useJwt.setToken(response.data.data?.accessToken);
              useJwt.setRefreshToken(response.data.data?.refreshToken);
              useJwt.profile().then((res) => {
                const userData = res.data.data;
                if (userData.isSuperAdmin) {
                  userData.ability = [
                    {
                      action: 'manage',
                      subject: 'all',
                    },
                  ];
                } else {
                  userData.ability = userData.permissions.map((per) => ({
                    action: per.code,
                    subject: per.group,
                  }));
                }
                userData.ability.push({
                  action: 'read',
                  subject: 'Auth',
                });
                localStorage.setItem('userData', JSON.stringify(userData));
                this.$ability.update(userData.ability);
                this.loading = false;
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace({ name: constRouter.DASHBOARD.name }).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  });
                });
              });
              // fake data for mock api
              // const userData = {
              //   id: 1,
              //   fullName: 'John Doe',
              //   username: 'johndoe',
              //   password: 'admin',
              //   // eslint-disable-next-line global-require
              //   avatar: require('@/assets/images/avatars/13-small.png'),
              //   email: 'admin@demo.com',
              //   role: 'admin',
              //   ability: [
              //     {
              //       action: 'manage',
              //       subject: 'all',
              //     },
              //   ],
              //   extras: {
              //     eCommerceCartItemsCount: 5,
              //   },
              // };
            })
            .catch((error) => {
              this.loading = false;
              if (error.response?.status === 422) {
                if (error.response?.data?.errors) {
                  this.$refs.loginForm.setErrors(error.response.data.errors);
                } else {
                  this.$refs.loginForm.setErrors({
                    username: error.response.data.message,
                  });
                }
              } else {
                console.log('err else');
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
